import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// https://github.com/christo-pr/dangerously-set-html-content#readme

const CodeExecutor = ({ code }) => {
  const divRef = useRef(null);

  useEffect(() => {
    const slotHtml = document.createRange().createContextualFragment(code); // Create a 'tiny' document and parse the html string
    divRef.current.innerHTML = ''; // Clear the container
    divRef.current.appendChild(slotHtml); // Append the new content
  }, [code]);

  return <div ref={divRef} />;
};

CodeExecutor.propTypes = {
  code: PropTypes.string.isRequired,
};

export default CodeExecutor;
